.second-navbar{
    height: 50px;
    width: max-content;
    position: absolute;
    top: 70px;
    right: 50%;
    transform: translateX(50%);
}

.navbar-nav{
    flex:1
}

.navbar-nav.logo-part{
    flex:0.5
}

.btn-sub-color{
    height: 35px;
    line-height: 30px;
    margin: 0 15px;
}

.avatar-container{
    width: 35px;
    height: 35px;
    background-color: rgba(173, 223, 230, 0.3);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
   
.user-menu .dropdown-menu{
    /* width: auto; */
    box-shadow: 0 0 5px rgba(0,0,0, .2) !important;
    border: none;
    
}

.hamburger-inner, .hamburger-inner::after, .hamburger-inner::before{
    background-color: #4b3a85 !important;
    width: 30px !important;
    height: 3px !important;
}
.blog-btn {
    display: block;
    width: fit-content;
    text-align: center;
    background-color: #D4E9EA;
    border: 1px solid transparent !important;
    border-radius: 50px;
    padding-left: 5px;
    padding-right: 5px;
    font-weight: bold;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    cursor: pointer
  }
  .blog-btn:hover, .blog-btn:focus{
    background-color: transparent;
  }
.badge-success{
    position: absolute;
    left: -21%;
    top: -23%;
    padding: .25em .4em !important;
    animation-name: badge-animation;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}

@keyframes badge-animation {
    0%{
        color: #fff;
    }
    25%{
        color: #fff;
    }
    50%{
        color: #fff;
    }
    75%{
        color: #fff;
    }
    100%{
        color: #28a745;
    }
}

@media screen and (max-width: 1199px) {
    .navbar-nav.logo-part{
        flex:0.3
    }

    .nav-item .nav-link{
        font-size: 0.9rem;
    }

    .btn-sub-color{
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 991px) {
    .second-navbar{
        height: auto;
        position: relative;
        top: 0;
        right: 0;
        z-index: 2000;
        transform: translateX(0);
    }

    .login-btn{
        width: 100% !important;
        max-width: 290px;
    }
}

@media screen and (max-width: 600px) {
    .second-navbar{
        width: 100%;
    }
}
