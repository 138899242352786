.course-wrapper{

    .course-inner-wrapper{
        width: 80%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-top: 5px;
        padding-bottom: 20px;
        font-family: TheSansArabBold;

        .title{
            color: #ff6575;
            font-size: 60px;
        }
        .sub-title{
            background-color: #FCE77D;
            color: #6C52BE;
            font-size: 45px;
        }
    
        .iframe-wrapper{
            text-align: center;
            height: 350px;
            width: 650px;
            box-shadow: -10px 10px 10px #707070;
            background-color: #FFF;
            position: relative;
        }
    
        .applying-section{
            animation: fadeRight 2s ease-in;
            color: #ff6575;
            p{
                font-size: 30px;
            }
        
            a{
                background-color: #fce77d;
                color: #6C52BE;
                font-size: 20px;
                border-radius: 35px;
                padding: 10px;
            }
            a:hover{
                background-color: transparent;
                color: #6C52BE;
               border: 2px solid #fce77d;
            }
        }

        @media (max-width: 1200px) {
            
            .title{
                font-size: 55px;
            }
            .sub-title{
                font-size: 36px;
            }
    
            .iframe-wrapper{
                width: 470px;
                height: 350px;
            }
        }
    
        @media (max-width: 992px) {
            width: 90%;
            .title{
                font-size: 38px;
            }
            .sub-title{
                font-size: 28px;
            }
        
            .iframe-wrapper{
                width: 400px;
            }
    
            .applying-section{
                p{
                    font-size: 20px;
                }
            
                a{
                    font-size: 12px;
                }
            }
        }

        @media (max-width: 767px) {
            padding: 10px;
            .sub-title{
                font-size: 35px;
                background-color: transparent;
            }

            .iframe-wrapper{
                width: 350px;
                height: 300px;
            }

            .second-span{
                display: block;
                background-color: #FCE77D;
            }
        }

        @media (max-width: 600px) {
            width: 100%;
            margin-top: 35px;
            .title{
                font-size: 28px;
            }
            
            .iframe-wrapper{
                height: 238px;
                width: 300px;
                margin: 10px 0 15px;
            }
    
            .applying-section{
                p{
                    font-size: 20;
                }
            }
        }
    }


}

.left-background{
    position: fixed;
    z-index: -1;
    left: 0;
    top: 0;
    transform: translateX(-12px);
    height: 100%;
    width: 33%;
    img{
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    @media (max-width: 600px) {
        display: none;
    }
}

@keyframes fadeRight {
    0%{
        opacity: 0;
        transform: translateX(-200px);
    }
    100%{
        opacity: 1;
        transform: translateX(0);
    }
}