$dark-blue: #4b3a85;
$light-blue: #2bc3cc;
$white: #FFF;
$black: #000000;
$red: #f66271;
$silver: #dbdbdb;

.top-border {
    border-top: 1px solid $dark-blue; }

